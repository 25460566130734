import {z} from 'zod'

export const PaginationResource = z.object({
  current_page: z.number(),
  from: z.number().nullable(),
  last_page: z.number(),
  per_page: z.number(),
  to: z.number().nullable(),
  total: z.number(),
})

export type PaginationResource = z.infer<typeof PaginationResource>
